import React from "react";
import "./LoadingTile.css";

export default function LoadingTile() {
  return (
    <div className="projects-loading-tile">
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
    </div>
  );
}
