import React from "react";
import "./Bio.css";

export default function Bio() {
  return (
    <div className="hero-bio">
      <h5 className="hero-bio-text">
        Masters of Computer Systems Engineering student at the University of
        Bath,
      </h5>
      <h5 className="hero-bio-text">
        Full Stack Developer | Machine Learning Engineer | Game Developer
      </h5>
    </div>
  );
}
